import { useState, useEffect } from 'react';

import DynamicContainer from 'react-components/src/ui/layout/container/animated-dynamic-height.containter';
import Text from 'react-components/src/components/text';
import axios from 'axios';

import { blue, green, red, yellow } from '@benepass/colors';
import { Icon } from 'react-components';

import type { Message } from '../../api/status';

const StatusWidget = () => {
  const [response, setResponse] = useState<{
    status: 'idle' | 'loading' | 'success' | 'error';
    data?: Message | null;
    error?: Error | null;
  }>({
    status: 'success',
    data: null,
    error: null,
  });

  const fetchStatus = async () => {
    setResponse({ status: 'loading', data: null });
    try {
      const res = await axios.get<Message>('/api/status');
      setResponse({ status: 'success', data: res.data });
    } catch (error) {
      setResponse({ status: 'error', data: null, error: error as Error });
    }
  };

  useEffect(() => {
    fetchStatus();
  }, []);

  if (response.status === 'success' && response.data) {
    const message = response.data;

    return (
      <div
        className={[
          message.variant === 'warning' ? 'bg-yellow-10 border border-yellow-100' : '',
          message.variant === 'info' ? 'bg-blue-10 border border-blue-100' : '',
          message.variant === 'success' ? 'bg-green-10 border border-green-100' : '',
          message.variant === 'error' ? 'bg-red-10 border border-red-100' : '',
          'px-2 md:px-6 py-6 md:py-4',
          'flex flex-col md:flex-row gap-2 md:gap-5',
          'md:justify-center',
          'relative z-10',
        ].join(' ')}
      >
        <div>
          <Icon
            color={(() => {
              switch (message.variant) {
                case 'warning':
                  return yellow['100'];
                case 'success':
                  return green['100'];
                case 'error':
                  return red['100'];
                case 'info':
                default:
                  return blue['100'];
              }
            })()}
            size={24}
            name={(() => {
              switch (message.variant) {
                case 'warning':
                  return 'Warning';
                case 'success':
                  return 'CheckCircle';
                case 'error':
                  return 'XCircle';
                case 'info':
                default:
                  return 'Info';
              }
            })()}
          />
        </div>

        <div className="flex flex-col">
          <Text type="header-3">{message.title}</Text>
          <Text type="body" size="md">
            {message.description}
          </Text>
        </div>
      </div>
    );
  }

  return null;
};

const Wrapper = () => (
  <DynamicContainer>
    <StatusWidget />
  </DynamicContainer>
);

export default Wrapper;
