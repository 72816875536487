const config = {
  authorizationEndpoint: 'https://cognito.benefitsapi.com/authorize',
  benepassRealm: 'https://api.benefitsapi.com/auth/realm',
  defaultClient: 'employeeWebApp',
  sentry: {
    dsn: 'https://94bb423ffc8d477db80511016383cf6b@o463927.ingest.sentry.io/5763793',
    environment: 'production',
  },
  clients: {
    localAdmin: {
      redirect_uri: 'http://localhost:3000/?v=2',
      client_id: '6l7jeu4r44kgndgeab4aot355m',
    },
    localEmployee: {
      redirect_uri: 'http://localhost:3001/?v=2',
      client_id: '6l7jeu4r44kgndgeab4aot355m',
    },
    localOps: {
      redirect_uri: 'http://localhost:3002/?v=2',
      client_id: '6l7jeu4r44kgndgeab4aot355m',
    },
    localClaims: {
      redirect_uri: 'http://localhost:3003/?v=2',
      client_id: '6l7jeu4r44kgndgeab4aot355m',
    },
    employeeWebApp: {
      redirect_uri: 'https://app.getbenepass.com/?v=2',
      client_id: '6l7jeu4r44kgndgeab4aot355m',
    },
    adminDash: {
      redirect_uri: 'https://admin.getbenepass.com/?v=2',
      client_id: '6l7jeu4r44kgndgeab4aot355m',
    },
    opsDash: {
      redirect_uri: 'https://ops.getbenepass.com/?v=2',
      client_id: '6l7jeu4r44kgndgeab4aot355m',
    },
    claims: {
      redirect_uri: 'https://claims.getbenepass.com/?v=2',
      client_id: '6l7jeu4r44kgndgeab4aot355m',
    },
    employeeWebAppBeta: {
      redirect_uri: 'https://app.beta.getbenepass.com/?v=2',
      client_id: '6l7jeu4r44kgndgeab4aot355m',
    },
    adminDashBeta: {
      redirect_uri: 'https://admin.beta.getbenepass.com/?v=2',
      client_id: '6l7jeu4r44kgndgeab4aot355m',
    },
    mobile: {
      redirect_uri: 'com.getbenepass.app://cognito.benefitsapi.com/callback',
      client_id: '2praup7nm1cecj8nslsmdla9q',
    },
    ssoCheck: {
      redirect_uri: 'https://sso-check.benefitsapi.com/?v=2',
      client_id: '6l7jeu4r44kgndgeab4aot355m',
    },
    drop: {
      redirect_uri: 'https://drop.getbenepass.com/',
      client_id: '6l7jeu4r44kgndgeab4aot355m',
    },
    preview: {
      redirect_uri: 'https://preview.getbenepass.com/result',
      client_id: '6l7jeu4r44kgndgeab4aot355m',
    },
  },
};

export default config;
