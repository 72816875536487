import React from 'react';
import ReactNotification from 'react-notifications-component';

import Auth from './ui/Auth';
import StatusWidget from './widgets/status.widget';

const NewApp = React.lazy(() => import('./app/app'));

const App = () => {
  const searchParams = new URLSearchParams(window.location.search);

  if (searchParams.get('design') === 'old') {
    return (
      <React.Suspense fallback={<div>Loading...</div>}>
        <StatusWidget />
        <ReactNotification />
        <Auth />
      </React.Suspense>
    );
  }

  return (
    <>
      <StatusWidget />
      <ReactNotification />
      <NewApp />
    </>
  );
};

export default App;
