/* eslint-disable */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useMemo, useState } from 'react';
import OTPInput from '../components/OTPInput';
import { store } from 'react-notifications-component';
import Button from 'react-components/src/components/button';
import { LayoutLoginContentBox } from 'react-components';
import LoadingSpinnerIcon from 'react-components/src/components/loading-spinner-icon';
import Text from 'react-components/src/components/text';

const VerificationForm = ({ email, loginWithCode, onResend, onBack, errorMessage }) => {
  const [code, setCode] = useState('');
  const [checking, setChecking] = useState(false);
  const parsedEmail = useMemo(() => {
    return email.replace(/\+.*@/, '@');
  }, [email]);

  const onChangeCode = async (value) => {
    setCode(value);
    if (value.length === 6) {
      setChecking(true);
      try {
        await loginWithCode(email, value);
      } catch (error) {
        setCode('');
        setChecking(false);
      }
    }
  };

  const onClickedResend = async () => {
    setChecking(true);
    setCode('');
    await onResend(email);
    setChecking(false);
    store.addNotification({
      message: `We’ve sent another code to ${email}, you should get it soon!`,
      type: 'success',
      container: 'top-center',
      dismiss: {
        duration: 3000,
      },
    });
  };

  return (
    <div className="flex flex-col gap-10 items-center px-4">
      <div>
        <div className="flex flex-col gap-1 items-center pt-8">
          <Text type="display-4" className="text-grayscale-80">
            Enter verification code
          </Text>

          <div className="text-center items-center">
            <Text type="body" size="xl" className="text-grayscale-64 gap-1">
              Check{' '}
              <Text type="body" size="xl" className="font-semibold" as="span">
                {parsedEmail}
              </Text>{' '}
              for a verification code. Not your email?{' '}
              <Text role="button" onClick={onBack} className="underline">
                Change
              </Text>
            </Text>
          </div>
        </div>
      </div>

      <div className="flex flex-col self-center gap-6">
        {checking ? (
          <LoadingSpinnerIcon height="64px" />
        ) : (
          <div className="w-full max-w-[500px]">
            <OTPInput value={code} onChange={onChangeCode} />
          </div>
        )}
        {errorMessage}
      </div>

      <div className="text-center items-center">
        <Button onClick={onClickedResend} variant="link" className="underline">
          Didn&apos;t receive it? Resend
        </Button>
      </div>
    </div>
  );
};

export default VerificationForm;
